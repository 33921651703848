import styled from "styled-components";
import { styles } from "../styles";

export const Sidebar = styled.ul`
  list-style: none;
  padding: 30px 40px;
  margin: 0;
  font-size: 0.9em;
  li {
    margin: 15px 0px;
    font-size: 0.9em;
  }
  hr {
    margin: 25px 0px;
    background-color: ${styles.color.white}18;
  }
`;
