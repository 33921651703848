import gql from "graphql-tag";

export const REQUEST = gql`
  mutation AddCcpaQueue($input: CcpaQueueInputType!, $Verification: String!) {
    AddCcpaQueue(CcpaQueue: $input, Verification: $Verification) {
      CcpaQueueId
      FirstName
      RequestType
      StateCode
    }
  }
`;

export const CPA_RESPONSE = gql`
  query GetCpaResponse(
    $where: String
    $limit: Int
    $page: Int
    $orderby: String
    $count: Boolean
  ) {
    CpaResponse(
      where: $where
      limit: $limit
      page: $page
      orderby: $orderby
      count: $count
    ) {
      ButtonText
      CpaResponseId
      CreatedDate
      ModifiedDate
      ResponseText
      ResponseType
      StateCode
    }
  }
`;