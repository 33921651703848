/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum RequestTypeEnum {
  Delete = "Delete",
  Email = "Email",
  Info = "Info",
  Sell = "Sell",
}

export interface CcpaQueueInputType {
  Email: string;
  FirstName: string;
  LastName: string;
  PhoneNumber?: string | null;
  RequestType?: RequestTypeEnum | null;
  Status?: number | null;
  Address?: string | null;
  ZipCode: string;
  Referrer?: string | null;
  StateCode?: string | null;
  Info?: string | null;
  IsDeleteRequest?: boolean | null;
  IsRightRequest?: boolean | null;
  IsSoldRequest?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
