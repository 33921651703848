import {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  useReducer,
  Reducer,
  createRef,
  RefObject,
} from "react";
import { FieldError } from "react-hook-form/dist/types";
import { formTools } from "../utils";

export const handleErrorMessage = (name: string, errors: any) => {
  return {
    helperText: formTools.GetErrorMessage(name, errors),
    error: errors && !!errors[name],
  };
};

export const usePassword = () => {
  const [show, setShow] = useState(false);
  const toggle = () => {
    setShow(!show);
  };
  const setShowTrue = () => {
    setShow(true);
  };
  const setShowFalse = () => {
    setShow(false);
  };
  return { show, toggle, setShowTrue, setShowFalse };
};
interface SelectHelpersState {
  inputLabel: {
    [key: string]: RefObject<any>;
  };
  labelWidth: { [key: string]: number };
}
interface SelectHelpersReturn {
  inputLabel: SelectHelpersState["inputLabel"];
  labelWidth: SelectHelpersState["labelWidth"];
  addToInputLabel: (n: string) => void;
}

const useSelectHelpers = (): SelectHelpersReturn => {
  const [inputLabel, setInputLabel] = useState<
    SelectHelpersState["inputLabel"]
  >({});

  const [labelWidth, setLabelWidth] = useState<
    SelectHelpersState["labelWidth"]
  >({});

  const addToInputLabel = (n: string) => {
    console.log(`Adding ${n} to InputLabel`);
    setInputLabel({ ...inputLabel, [n]: createRef() });
  };

  useEffect(() => {
    console.log("here", inputLabel, labelWidth);
    const newLabelWidths = Object.entries(inputLabel).reduce(
      (a, [k, v]) => ({
        ...a,
        [k]: v?.current?.offsetWidth ?? 0,
      }),
      labelWidth
    );
    console.log(newLabelWidths);
    setLabelWidth(newLabelWidths);
  }, [inputLabel]);

  return {
    inputLabel,
    labelWidth,
    addToInputLabel,
  };
};

export const useFormHelpers = () => {
  const Select = useSelectHelpers();
  return {
    Select,
  };
};
