import React, { useState, useEffect } from "react";
import { Modal } from "@material-ui/core";
import styled from "styled-components";
import { styles } from "../styles";
import { useModal } from "./modal";

interface Info {
  title?: string;
  description?: string;
  confirm?: string;
  cancel?: string;
}

interface State {
  isOpen: boolean;
  call: () => any;
  info: Info;
}

export const useConfirmation = (): {
  Modal: () => JSX.Element;
  useConfirmationModal: (call: () => any, info: Info) => void;
} => {
  const { Modal, closeModal, openModal, setChildren } = useModal();
  const [call, setCall] = useState<State["call"]>(() => () => {
    console.log("asdf");
  });
  const [info, setInfo] = useState<State["info"]>({});
  const useConfirmationModal = (call: () => any, info: Info) => {
    console.log("test");
    setCall(() => call);
    setInfo(info);
    openModal();
  };
  useEffect(() => {
    setChildren(
      <>
        <h2>{info.title || "Are you sure?"}</h2>
        <span>{info.description || "This action is permanent"}</span>
        <S.Buttons>
          <S.CancelButton
            onClick={() => {
              closeModal();
            }}
          >
            {info.cancel || "No, Go Back"}
          </S.CancelButton>
          <S.ConfirmButton
            onClick={() => {
              call();
              closeModal();
            }}
          >
            {info.confirm || "Yes, I Am Sure"}
          </S.ConfirmButton>
        </S.Buttons>
      </>
    );
  }, [info]);

  return { Modal, useConfirmationModal };
};

const S = {
  CancelButton: styled.button`
    ${styles.button.base};
    ${styles.button.error};
  `,
  ConfirmButton: styled.button`
    ${styles.button.base};
    ${styles.button.success};
  `,
  Modal: styled.div`
    background-color: ${styles.color.white};
    padding: 40px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 500px;
    text-align: center;
  `,
  Buttons: styled.div`
    margin-top: 40px;
    ${styles.flex.row};
    justify-content: space-between;
  `,
};
