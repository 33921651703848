import styled from "styled-components";

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  transition: visibility 1s, opacity 1s, linear;

  .dropdown-content {
    display: block;
    position: absolute;
    z-index: 1000;
    right: 0px;
    opacity: 0;
    visibility: hidden;
  }

  :hover .dropdown-content {
    opacity: 1;
    visibility: visible;
    transition: visibility 1s, opacity 1s, linear;
  }
`;
