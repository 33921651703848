import React, { ReactNode } from "react";
import { PageLoading } from ".";
import styled from "styled-components";
import { styles } from "../styles";
import { toast } from "react-toastify";
import { ApolloError } from "apollo-client";

export const useQueryLoad = (
  loads: boolean[],
  errors: (ApolloError | undefined)[],
  data?: (any | undefined)[],
  fields?: string[],
  messages?: string[]
): ReactNode | undefined => {
  if (loads.some(l => l)) {
    return <PageLoading />;
  } else if (errors.some(e => !!e)) {
    return (
      <S.ErrorList>
        {errors
          .filter(e => !!e)
          .map(e => {
            toast.error(e && e.message);
            return <pre>{JSON.stringify(e, null, 2)}</pre>;
          })}
      </S.ErrorList>
    );
  } else if (data && fields && data.some((d, i) => !!(d && !d[fields[i]]))) {
    return (
      <S.ErrorList>
        {data
          .filter(d => !!d)
          .map(d => (
            <pre>{JSON.stringify(d, null, 2)}</pre>
          ))}
      </S.ErrorList>
    );
  }
};

const S = {
  ErrorList: styled.pre`
    ${styles.flex.column};
  `,
};
