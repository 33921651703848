import React from "react";
import { styles } from "../styles";
import ReactLoading from "react-loading";

export const ButtonLoading = () => (
  <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
    <ReactLoading
      type="spin"
      color={styles.color.white}
      height="25px"
      width="25px"
    />
  </div>
);

export const PageLoading = () => (
  <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
    <ReactLoading
      type="spin"
      color={styles.color.primary}
      height="30vh"
      width="25px"
    />
  </div>
);
